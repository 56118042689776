import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "️-inventing-on-principle",
      "style": {
        "position": "relative"
      }
    }}>{`🏗️ Inventing on Principle`}<a parentName="h1" {...{
        "href": "#%EF%B8%8F-inventing-on-principle",
        "aria-label": "️ inventing on principle permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`The five principles outlined in `}<a parentName="p" {...{
        "href": "/learn/module-6/serenity/#principled-layers"
      }}>{`Serenity`}</a>{` are very useful
technical guidelines for designing complex systems. However, they are not personal principles
and have nothing to say about our motivation as human beings for wanting to build out and
participate in the Ethereum 2.0 economic elder game.`}</p>
    <p>{`We'll turn to Bret Victor to help us think about how we might meaningfully merge our technical
practice with a personal principle so as to live response-ably: cultivating both an `}<em parentName="p">{`awareness`}</em>{`
of that which we must uphold, protect or respond to `}<strong parentName="p">{`and`}</strong>{` the `}<em parentName="p">{`ability`}</em>{` to do so with care.`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/PUv66718DII" mdxType="Video" />
    <h3 {...{
      "id": "how-does-this-fit-into-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`How does this fit into Kernel?`}<a parentName="h3" {...{
        "href": "#how-does-this-fit-into-kernel",
        "aria-label": "how does this fit into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`This is the kernel of Kernel. Bret won't teach you about decentralized systems; or money,
finance and speech; or about what the web means in the context of a networked species.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"This is about a way of living your life which most people don't talk about. As you approach
your career, you'll hear about 'following your passion', or 'doing something you love'. This is
about something different: `}<strong parentName="p">{`finding and following a principle`}</strong>{`. Something you believe is
important and necessary and right and using that to guide what you do."`}</p>
    </blockquote>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`This is a talk in three parts: Bret describes his own principle, then describes some other
people who have lived principled lives, and finally uses both previous sections to illustrate how
you can find and follow your own principle.`}</p>
    <h3 {...{
      "id": "immediate-creation",
      "style": {
        "position": "relative"
      }
    }}>{`Immediate Creation`}<a parentName="h3" {...{
        "href": "#immediate-creation",
        "aria-label": "immediate creation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"Bringing ideas into the world is one of the most important things that people do and great
ideas - art, stories, inventions and scientific theories - take on lives of their own which
give meaning to our lives as people."`}</p>
    </blockquote>
    <p>{`Bret is primarily concerned with what sorts of tools provide healthy environments for ideas to
grow. Thus, his principle is:`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 Creators need an immediate connection to what they create.`}</p>
    </blockquote>
    <p>{`What this means is that, when you're making something and you make a change or a decision, you
need to see its effect directly and instantaneously. Nothing should be hidden: creators need to
see what they're doing. One place where this principle is violated is `}<strong parentName="p">{`coding.`}</strong></p>
    <p>{`Specifically, we edit code in a text editor, compile it, run it and go see what it looks like; then make some changes, compile and run it again and check the effect of that new change. Most of our time is spent working in a text editor, blindly, without an immediate connection to the output.`}</p>
    <p>{`The first step needed is to link the code and the output together seamlessly in one environment. Then, we can start thinking of ways to change our work other than typing new code. Bret demonstrates this by "dialling" numbers up and down in the code editor with a simple ctrl + click mechanism, and having the effect directly reflected in the running program. This allows you to explore the space and pick the magnitude which feels best to you artistically, without needing to go through each possible number manually. He demonstrates how this simple change allowed him to discover a means of animation he would never have otherwise known about.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"So much of creation is discovery. And you can't discover anything if you can't see what
you're doing `}{`[...]`}{` Having an immediate connection allows ideas to surface, and develop, in
ways which were not before possible."`}</p>
    </blockquote>
    <p>{`There's still a problem though: we have the animated output, and we have the code, but we have to maintain the mapping between the two in my head to make sense of things. Bret solves this by introducing an option to click on one part of the picture to see which line of code it corresponds to. As it turns out, this is also useful for navigation, `}<strong parentName="p">{`allowing us to make changes as quickly as we think of them`}</strong>{`, which is critical to the creative process.`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 There are thoughts we can't think: we need our tools to help us discover them.`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What is Bret Victor's guiding principle? `}</p>
        <p>{`Creators need an immediate connection to what they create.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: Tools which give us this immediate connection lead us to what kind of thoughts?`}</p>
        <p>{`Thoughts we can't think in isolation.`}</p>
      </Card>
    </Flash>
    <h4 {...{
      "id": "interactivity",
      "style": {
        "position": "relative"
      }
    }}>{`Interactivity`}<a parentName="h4" {...{
        "href": "#interactivity",
        "aria-label": "interactivity permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <p>{`Another concept that poses difficulty to the coder/creator is time.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"If you have a process in time, and you want to see changes immediately; you have to map time
to space `}{`[...]`}{` Creators need to be able to see what they're doing. If you're designing
something embedded in time, you need to be able to see across time, otherwise you're designing
blind."`}</p>
    </blockquote>
    <p>{`In Bret’s first example, there was no state: no sense of time or interactivity. Next, he shows a platform game which has all the features of his previous example, but which may also be paused at any point and rewound—for instance to adjust the future trajectory of a character's jump so they end up in exactly the right place without unnecessary fuss for the creator`}</p>
    <blockquote>
      <p parentName="blockquote">{`"As I was designing this, I noticed that it's fun to play with gravity. I bet you could make
an entire game from that one mechanic. In fact, you could probably make a game from fiddling
with any single line of code here as a way of `}<a parentName="p" {...{
          "href": "/learn/module-5/reveal-the-universe/#exploring-well"
        }}>{`exploring possibly rich mechanics`}</a>{`."`}</p>
    </blockquote>
    <h4 {...{
      "id": "abstract-algorithms",
      "style": {
        "position": "relative"
      }
    }}>{`Abstract Algorithms`}<a parentName="h4" {...{
        "href": "#abstract-algorithms",
        "aria-label": "abstract algorithms permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <p>{`Bret then talks about binary search and demonstrates how the code for such an algorithm gives no
immediate sense of what it does.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"You have to 'play computer' and simulate in your head what each line of code will do. To a
large extent, the people who we consider to be good software developers are just those people
who are good at playing computer. But if we're writing our code `}<em parentName="p">{`on a computer`}</em>{`, why are we
simulating what a computer would do rather than just having it do it and show us!?"`}</p>
    </blockquote>
    <p>{`In particular, he uses this example to show how easy and intuitive it could be, given the proper tools, to find different kinds of bugs—both simple ones like accidentally using an invalid array index, and non-trivial ones where the range within which we're searching becomes distorted and never returns anything usable. This is, he says, "what it looks like to write an algorithm without a blindfold on."`}</p>
    <p>{`Our current conception of what a program is—a list of textual definitions that you hand to a compiler—is derived from languages made in the 1950's when we were still using punch-cards. None of these languages, or the machines they ran on, were interactive—and this assumption is still with us today, even though the media has changed drastically. People still think using a REPL amounts to "interactive programming" because that was the best you could do on a teletype!`}</p>
    <h4 {...{
      "id": "golden-rules",
      "style": {
        "position": "relative"
      }
    }}>{`Golden Rules`}<a parentName="h4" {...{
        "href": "#golden-rules",
        "aria-label": "golden rules permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <p>{`We can illustrate what we are hinting at here with programming and software, but `}<strong parentName="p">{`the principle itself has to do with any kind of creation`}</strong>{`. Bret shows an electric circuit to illustrate the breadth of what he has in mind.`}</p>
    <p>{`In electric circuit design, we are mostly interested in the data associated with the variables,
and yet traditional tools and environments confine us to static representations we must then
simulate in our heads. Bret uses this to present `}<strong parentName="p">{`the two golden rules of information design:`}</strong></p>
    <ol>
      <li parentName="ol">{`Show the data.`}</li>
      <li parentName="ol">{`Show comparisons.`}</li>
    </ol>
    <p>{`Here's the really mind-blowing piece: instead of the representation of a circuit being made
out of "little squiggly symbols", `}<strong parentName="p">{`it is made out of data`}</strong>{`. The symbols exist because they
were appropriate for the medium of pencil and paper, but when you have a new medium, you need
to rethink your representations to give creators more immediate connections to what they are
creating.`}</p>
    <p>{`The last example he provides is entirely outside the field of engineering, in order to
demonstrate the generality of this principle. Bret shows how animation can be handled in a
far more elegant way than Flash. It's really worth just watching yourself. Go to 30:50 to see
it unfold.`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 Immediate connection is the fertilizer we need to `}<a parentName="p" {...{
          "href": "/learn/module-4/the-garden"
        }}>{`grow gardens`}</a>{` of great ideas.`}</p>
    </blockquote>
    <h3 {...{
      "id": "motivation",
      "style": {
        "position": "relative"
      }
    }}>{`Motivation`}<a parentName="h3" {...{
        "href": "#motivation",
        "aria-label": "motivation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`When I see a violation of this principle, when I see creators constrained by their tools,
their ideas compromised, I don't see that as an opportunity. I'm not excited by finding a
problem to solve. I am not in this for the 'joy of making things'. Ideas are very precious to
me, and when I see ideas dying, it hurts. I see a tragedy. To me, it feels like a moral wrong.
It feels like an injustice, and I feel it is my responsibility to do something.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Not opportunity: `}<strong parentName="p">{`responsibility`}</strong>{`.`}</p>
    </blockquote>
    <p>{`Bret is not trying to convince you to take up his principle, but rather to highlight that the
words he is using - "responsibility", "injustice", "moral wrong" - aren't words you generally hear in a technical field. Social concepts like censorship, gender discrimination, environmental degradation are all recognized as moral wrongs, yet we lack this tenor in our technical creations.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"The purpose of this talk is to tell you that activists are not limited to social causes.
As a technologist, you can recognize a wrong in the world, you can have a vision for what a
better world could be, and you can dedicate yourself to fighting for a principle.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Social activists generally dedicate themselves to a principle by organizing, but `}<strong parentName="p">{`you can
fight for a principle by inventing`}</strong>{`."`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: How can technologists fight for their principles and exercise responsibility?`}</p>
        <p>{`By inventing.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "visionaries",
      "style": {
        "position": "relative"
      }
    }}>{`Visionaries`}<a parentName="h3" {...{
        "href": "#visionaries",
        "aria-label": "visionaries permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`In the late 70's at Xerox Parc, Larry Tesler and his colleagues thought that personal
computing - which was not considered feasible by anyone else - had huge potential to change
how people thought and lived. At the time, software interfaces were designed around so-called "`}<strong parentName="p">{`modes`}</strong>{`". `}<Link to="https://stackoverflow.blog/2017/05/23/stack-overflow-helping-one-million-developers-exit-vim/" mdxType="Link">{`Vim still famously does this`}</Link>{`.
By pioneering the concept of software user studies, Tesler observed that the complexity of modes
was a barrier that many people, even with training, couldn't cross. This was therefore a
threat to his dream of personal computers.`}</p>
    <p>{`He made it his personal mission to get rid of modes and he formed a principle: `}<strong parentName="p">{`no person should be trapped in a mode`}</strong>{`. His slogan was "Don't mode me in!" This principle informed everything he did and he eventually came up with a text editor called Gypsy which was the beginning of text editors  as we know them today.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This was a transformative change in allowing people to connect with computers. His ideas
about modelessness spread to the rest of the desktop interface and today, are so ingrained in
our computers, that we do not notice them `}{`[...]`}{` So, how can we describe what Larry did? Yes,
he 'invented' cut-copy-paste, but this invention is very different to Thomas Edison inventing
the phonograph. Edison stumbled over the technology for audio recording and built it out as a
novelty, but `}<strong parentName="p">{`he didn't have any cultural intent`}</strong>{`, whereas what Larry did was entirely a
reaction to a particular cultural context.`}</p>
    </blockquote>
    <p>{`This is why we have placed such `}<a parentName="p" {...{
        "href": "/learn/module-1/joyful-subversion/#shifting-the-nature-of-work"
      }}>{`emphasis`}</a>{` on `}<a parentName="p" {...{
        "href": "/learn/module-3/time/#media-environments"
      }}>{`breaking`}</a>{` down our `}<a parentName="p" {...{
        "href": "/learn/module-3/humility/#brief"
      }}>{`culturally conditioned`}</a>{` `}<a parentName="p" {...{
        "href": "/learn/module-3/intention/"
      }}>{`modes`}</a>{` `}<a parentName="p" {...{
        "href": "/learn/module-4/art/"
      }}>{`of`}</a>{` `}<a parentName="p" {...{
        "href": "/learn/module-5/listening-stories/"
      }}>{`thinking`}</a>{`. It would not be entirely accurate to say that Larry "solved the problem" of modeless text
manipulation, because `}<strong parentName="p">{`nobody else saw it as a problem!`}</strong>{` For everyone else, modes were just
how computers worked - they were a fact of life. `}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: The difference between inventions for personal gain (Edison) and those done on principle (Tesler) is due to what kind of intent?`}</p>
        <p><a parentName="p" {...{
            "href": "/learn/module-3/remember/#designing-insight"
          }}>{`Cultural`}</a>{`.`}</p>
      </Card>
    </Flash>
    <blockquote>
      <p parentName="blockquote">{`"The first thing Larry did was recognize a wrong that was unacknowledged in the culture, which
is how many great social changes began too. What Elizabeth Cady Stanton did in championing
women's right to vote is a much closer model for what Larry actually did than the Edison model
of invention for the sake of patents. This is not a point of relative impacts, just one of
`}<strong parentName="p">{`motivation`}</strong>{` and `}<strong parentName="p">{`approach`}</strong>{`. Both Stanton and Tesler recognized an invisible wrong,
envisioned a world without that wrong, and dedicated themselves to fighting for a principle."`}</p>
    </blockquote>
    <p>{`This applies to many other seminal figures in the history of computer science. Doug Engelbart (who championed the idea of interactive computing) is best known for inventing the mouse, but he really came up with an entirely new way to work with knowledge. His explicit goal was to `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://youtu.be/sG3PWet8fDk"
        }}>{`enable humankind to solve the world's urgent problems`}</a></strong>{`. He had a vision of `}<strong parentName="p">{`knowledge workers using complex, powerful information tools to `}<a parentName="strong" {...{
          "href": "https://youtu.be/Rns8_e2JiKw"
        }}>{`harness their collective intelligence`}</a></strong>{`.`}</p>
    <p>{`Or Alan Kay, whose goal was "`}<strong parentName="p">{`to amplify human reach and bring new ways of thinking to a faltering civilization that desperately needed it`}</strong>{`." His approach was to work through children: if programming is regarded as a form of basic literacy, then kids will grow up to be adults who have developed new means for critical thought, and we'll have more enlightened societies. `}</p>
    <h3 {...{
      "id": "specific-insight",
      "style": {
        "position": "relative"
      }
    }}>{`Specific Insight`}<a parentName="h3" {...{
        "href": "#specific-insight",
        "aria-label": "specific insight permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"I'm not saying that you have to live this way, or even that you should. What I am saying is
that you `}<em parentName="p">{`can`}</em>{`. That this is an option which is available to you, though it is not one which
you'll hear about often `}{`[...]`}{` Instead the world will try to make you define yourself by a
skill."`}</p>
    </blockquote>
    <p>{`We are conditioned to centre our efforts around a major in college, or a job title in our career. This can be
worthwhile, and if you want to spend your life pursuing excellence by practicing a skill,
you can do that. That is the path of the craftsmen: the most common path. There's also the
path of the problem solver, typified by entrepreneurship or academic research, which is about
choosing a problem in a given field, working on it, and making a contribution there.`}</p>
    <p>{`Or, you can define yourself not by the problem you're solving, nor by your craft, but rather
`}<strong parentName="p">{`by your cause`}</strong>{`; by the principle you choose to uphold. This means having a vision and
bringing the world to that vision. Be patient: it can take time to find a principle, because
it is really a form of self-discovery. You're trying to figure out what your life is supposed
to be about.`}</p>
    <p>{`It took Bret ten years to find his principle. His advice based on those years of wondering is:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Do a lot of things. Make many things. Make many types of things. Study many things.
Experience many things. Use all of these experiences as a way of analyzing yourself by asking,
'Does this resonate with me?', "Does this repel me?', 'Do I not care?' Build up this corpus of
experiences you care about and then try to make sense of it, try to figure out `}<em parentName="p">{`why`}</em>{` you care.
What is the secret ingredient in all of these experiences that I am reacting to so strongly?"`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Victor describes the paths of the craftsmen, the entrepreneur, the academic, and the visionary. Why can it take a long time to find your principle on this final path if you choose it?`}</p>
        <p>{`Because principled living is really a form of `}<a parentName="p" {...{
            "href": "/learn/module-4/self-enquiry"
          }}>{`self-discovery`}</a>{`.`}</p>
      </Card>
    </Flash>
    <p>{`Confining yourself to practicing just one skill can make it difficult to get the broad range of experience which seems so valuable to doing principled work. `}</p>
    <p>{`Finally, a principle can't just be any old thing you believe in, like "make software simpler". This is a nice thought, but is too vague to be directly actionable. Larry Tesler liked simplicity, but he had this `}<strong parentName="p">{`specific nugget of insight`}</strong>{` that no person should be trapped in a mode.`}</p>
    <blockquote>
      <p parentName="blockquote">{`If your principle provides you with a specific insight, it will guide you, and you will
always know if what you're doing is right. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`There are many ways to live your life: that's maybe the most important thing you can
realise in life. Every aspect is a choice. But there are default choices. You can choose to
sleepwalk through your life and walk the path that's been laid out for you. You can choose to
accept the world as it is. But you don't have to. If there is something in the world you feel
is wrong, and you have a vision for what a better world could be, you can find your guiding principle.`}</p>
    </blockquote>
    <Video src="https://www.youtube-nocookie.com/embed/3QtklTXbKUQ?start=556" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      